@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Formular1-Regular";
  src: url("/public/fonts/Formula1-Regular-1.ttf");
}

@font-face {
  font-family: "Formular1-Bold";
  src: url("/public/fonts/Formula1-Bold-4.ttf");
}

#root{
  width: 100%;
  height: auto;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

@font-face {
  font-family: 'Formula1';
  src: url('./assets/font/Formula1-Bold_web_0.ttf') format('ttf'),
       url('./assets/font/Formula1-Regular_web_0.ttf') format('ttf'),
       url('./assets/font/Formula1-Wide_web_0.ttf') format('ttf');
  font-weight: bold, normal, light;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('./assets/font/TitilliumWeb-Bold.ttf') format('ttf'),
       url('./assets/font/TitilliumWeb-Regular.ttf') format('ttf');
  font-weight: bold, normal, light;
  font-style: normal;
}

.title-effect {
    text-shadow: 0 0 5px #E10600, 0 0 15px #E10600, 0 0 20px #E10600,
      0 0 40px #E10600, 0 0 60px #ff0000, 0 0 10px #E10600, 0 0 98px #ff0000;
    color: #FF5B5B;
    animation: blink 12s infinite;
    -webkit-animation: blink 12s infinite;
  }
  
  @-webkit-keyframes blink {
    20%,
    24%,
    55% {
      color: #111;
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px #E10600, 0 0 15px #E10600, 0 0 20px #E10600,
      0 0 40px #E10600, 0 0 60px #ff0000, 0 0 10px #E10600, 0 0 98px #ff0000;
    color: #FF5B5B;
    }
  }
  
  @keyframes blink {
    20%,
    24%,
    55% {
      color: #111;
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px #E10600, 0 0 15px #E10600, 0 0 20px #E10600,
      0 0 40px #E10600, 0 0 60px #ff0000, 0 0 10px #E10600, 0 0 98px #ff0000;
    color: #FF5B5B;
    } 
}

.shadow-top {
  box-shadow: 0 -8px 100px -3px rgba(0, 0, 0, 0.9);
}


