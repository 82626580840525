.card {
  position: relative;
  max-width: 330px;
  height: auto;
  border: 1px solid #000;
  border-radius: 20px;
  overflow: hidden;
}

.placeholder {
  height: 100%;
  width: 100%;
  border: 1px solid #111;
  background: linear-gradient(180deg, #000 -13.49%, rgba(0, 0, 0, 0.00) 19.33%), #15151D;
  /* filter: blur(24px) */
}

.placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.container {
  position: absolute;
  z-index: 2;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
  backdrop-filter: blur(28px);
}

.container-semafori {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 4px;
  max-width: 205px;
  width: 100%;
}

.container-semafori .line {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 8px;
  background: #000;
  transform: translateY(-50%);
}


.semaforo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border: 0.5px solid #2E2E2E;
  background-color: #000;
  padding: 4px;
  list-style-type: none;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.05);
}

.semaforo li {
  height: 30px;
  width: 30px;
  background-color: transparent;
  border-radius: 100%;
  background: #323232;
  border: 1px solid #323232;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
