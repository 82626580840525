body {
  background-color: #15151d;
  overflow: visible !important;
}

/* this code hides the date icon */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  visibility: hidden;
  -webkit-appearance: none;
}

.selectedCardBorder {
  border: 4px solid #27AE60 !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #51E18E 100%) !important;
}
